export interface Flag {
    name: string;
    image: string;
}

export interface Player {
    id: string;
    streak: string;
}

export interface History {
    result: string;
    date: string;
    points: number;
    flags: { flag: string, tries: number}[];
}
export const LEADERBOARD_MODE = {
    STREAK: 'streak',
    POINTS: 'points'
}
export interface Leaderboards {
    points: {name: string, points: number, highlight: boolean}[];
    streak: {name: string, streak: number, highlight: boolean}[];
}
