export const coupeSvg = (<g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)" stroke="none"><path d="M5221 11910 c-608 -13 -962 -34 -1011 -60 -26 -14 -26 -51 0 -73 16
-13 20 -29 20 -77 0 -44 -4 -62 -15 -66 -27 -11 -28 -44 -2 -72 25 -27 26 -28
32 -277 5 -168 4 -247 -3 -240 -5 6 -24 43 -40 83 -73 176 -199 293 -396 367
-236 89 -492 87 -691 -5 -343 -158 -591 -546 -635 -995 -42 -431 95 -965 343
-1334 116 -173 395 -491 592 -674 253 -235 457 -375 855 -587 203 -108 278
-156 340 -220 44 -45 89 -124 78 -136 -3 -3 -27 15 -52 40 -57 55 -100 70
-181 63 -208 -16 -322 -221 -228 -407 52 -101 161 -160 297 -160 87 0 128 11
188 49 71 45 101 104 106 210 4 92 15 119 31 80 6 -13 42 -98 80 -189 38 -91
104 -246 147 -344 77 -178 77 -180 61 -209 -22 -37 -21 -50 3 -72 11 -10 20
-28 20 -40 0 -13 -9 -67 -20 -120 -43 -209 -30 -232 216 -367 191 -105 254
-164 254 -237 0 -44 -8 -56 -40 -66 -49 -14 -110 -56 -126 -87 -25 -48 -15
-124 27 -194 21 -36 118 -144 255 -284 198 -202 223 -231 243 -285 45 -119 72
-379 41 -395 -8 -5 -66 -18 -128 -29 -202 -38 -285 -95 -358 -247 -46 -96 -48
-98 -124 -109 -144 -21 -277 -70 -334 -123 -36 -34 -82 -129 -96 -199 -11 -51
-13 -53 -53 -63 -229 -58 -292 -84 -361 -150 -115 -108 -155 -292 -196 -890
-24 -360 -37 -440 -81 -514 -33 -55 -34 -56 -129 -87 -198 -65 -332 -144 -410
-241 -54 -68 -81 -124 -110 -224 -50 -175 3 -275 200 -374 304 -152 914 -267
1725 -325 612 -43 1426 -39 1995 11 961 83 1550 238 1685 443 37 55 42 130 16
225 -33 119 -74 192 -155 271 -84 83 -170 131 -341 191 l-120 42 -33 61 c-43
77 -55 159 -77 526 -26 441 -56 641 -115 770 -56 123 -147 182 -365 236 -49
12 -95 26 -101 31 -6 4 -17 35 -24 67 -37 177 -135 252 -392 303 l-111 22 -38
83 c-46 100 -108 169 -184 207 -49 24 -140 48 -275 73 l-45 8 2 85 c4 110 17
200 44 286 21 68 23 71 240 290 217 221 289 311 309 386 23 85 -15 147 -114
189 -67 27 -78 55 -48 122 25 55 86 103 242 187 251 137 259 150 220 370 l-24
130 22 23 c26 28 28 54 6 78 -15 16 -13 26 29 126 37 88 193 448 252 581 8 17
19 32 23 32 5 0 12 -33 15 -74 13 -173 128 -265 316 -254 128 7 222 62 276
160 26 46 30 63 29 133 0 62 -5 89 -21 120 -46 88 -138 145 -244 153 -73 5
-111 -9 -166 -60 -40 -38 -57 -34 -37 9 45 91 142 164 404 303 442 234 662
393 965 695 265 265 463 511 567 705 188 349 288 798 258 1150 -55 639 -504
1121 -1009 1085 -348 -25 -603 -178 -701 -420 -42 -103 -46 -75 -33 240 6 160
8 170 30 191 29 26 29 45 2 70 -18 16 -20 28 -17 75 3 38 11 65 26 83 24 31
17 60 -15 70 -67 20 -399 40 -923 56 -447 14 -2099 20 -2579 10z m-1633 -685
c270 -54 503 -300 578 -608 17 -71 36 -279 28 -302 -9 -22 -19 -16 -46 25 -15
22 -48 47 -90 69 -154 78 -309 19 -354 -136 -41 -142 36 -304 166 -346 142
-46 282 9 346 136 14 27 34 87 44 134 10 47 21 87 24 90 9 9 15 -34 31 -217
50 -559 193 -1482 291 -1875 24 -97 110 -403 121 -431 13 -35 -19 -8 -68 57
-78 102 -174 181 -429 352 -266 177 -303 205 -435 313 -119 97 -333 315 -428
434 -220 276 -409 634 -497 939 -80 282 -61 570 58 845 116 266 281 461 437
515 72 25 121 26 223 6z m5972 -32 c185 -64 409 -396 485 -719 76 -321 23
-627 -180 -1039 -144 -294 -302 -513 -548 -764 -159 -161 -313 -284 -577 -461
-118 -79 -249 -169 -291 -201 -72 -54 -219 -203 -219 -221 0 -4 -4 -8 -10 -8
-16 0 -12 27 24 149 47 157 108 405 145 586 79 389 205 1250 236 1615 7 87 13
112 25 105 4 -3 11 -22 14 -42 23 -129 79 -223 160 -266 33 -18 57 -22 131
-22 74 0 98 4 131 22 161 86 194 339 59 450 -98 81 -260 63 -358 -41 -53 -57
-60 -44 -47 93 25 264 101 431 272 598 160 157 382 224 548 166z m-5540 -858
c36 -19 50 -43 50 -90 0 -31 6 -40 45 -67 50 -36 55 -53 23 -93 -32 -41 -93
-65 -166 -65 -67 0 -104 14 -138 52 -78 85 -70 204 18 258 40 24 126 27 168 5z
m5037 -15 c25 -11 51 -33 63 -52 43 -71 13 -186 -63 -236 -82 -55 -228 -26
-277 54 -11 17 -20 34 -20 37 0 4 20 18 44 32 40 24 46 31 52 73 9 63 20 81
57 97 45 20 95 18 144 -5z m-4495 -2789 c31 -20 52 -44 68 -75 30 -62 21 -107
-30 -158 -32 -32 -44 -38 -80 -38 -46 0 -103 29 -121 61 -5 11 -12 55 -13 97
-2 42 -7 85 -11 94 -9 23 38 47 96 48 33 0 57 -8 91 -29z m3958 11 c19 -16 21
-22 11 -40 -7 -13 -11 -53 -9 -92 2 -62 -1 -72 -23 -96 -52 -56 -111 -68 -166
-34 -127 77 -42 278 118 280 31 0 54 -6 69 -18z"/></g>)
